p.product_not_stock {
  margin-bottom: 2px !important;
  font-weight: bold;
  color: red;
}

.not_available {
  opacity: 0.4;
}

.not_available:hover {
  opacity: 1;
}

.cart-text h4 {
  padding-right: 20px;
  margin-top: 0px;
}

.st-inline-share-buttons {
  text-align: left !important;
  margin-top: 30px;
}

.sub-header-checkout {
  height: 45px;
}

a.read-more {
  color: #e21959;
}

.read-more {
  display: inline-block;
  margin-top: 8px;
  font-weight: 700;
}

.cte-select a {
  padding: 0;
  cursor: pointer;
  color: #2b2f4c;
  border-radius: 0;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}

.cte-select a.select {
  font-weight: bold;
}

.zeynep .submenu {
  height: 1250px;
}

.zeynep .submenu > label a {
  color: #e21959 !important;
}

a.active.pay-btn.pay-btn1.text-center {
  border: 1px solid red;
  padding: 10px 20px;
  border-radius: 50px !important;
}
.exceed_qty {
  color: red;
}
/* @media (max-width: 767px) {
    .zeynep .submenu {
        height: auto;
    }
} */
