.gallery-thumbs {
  img {
    width: 50px;
    padding: 5px;
    border: 1px solid #ccc;
    margin: 5px;
  }
  .active {
    img {
      border: 1px solid #4aaad2;
    }
  }

  padding-right: 15px;
  padding-left: 15px;
}
.magnify-container_1 {
  width: 90%;
}
